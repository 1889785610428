/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/
import { Widgets } from "@tol/tol-ui";
import { randomGenerator, useStyle, getMidColor } from '../utils/Utils';

function PracticalInfo() {
    const { randomImage, startColor, endColor } = randomGenerator();
    const imageSource = require("../assets/" + randomImage);
    const midColor = getMidColor(startColor, endColor);
    useStyle(startColor, endColor, midColor);

    const codeOfConduct = (
        <div className="information-container">
            <div className="custom-heading" style={{ display: "flex", justifyContent: "center", marginTop: "-15px", marginBottom: "-15px" }}>
                <img src={imageSource} style={{ width: "100%", maxWidth: "300px" }} alt="bga24-banner-logo" />
            </div>
            <p className="custom-heading">Code of Conduct</p>
            <p className="custom-sub-heading">Why We Have a Code</p>
            <p>
                Our goal is for the Biodiversity Genomics 2024 conference (BG24) and Biodiversity Genomics Academy 2024 (BGA24) to embody the best of our aspirations towards building a diverse and inclusive global genomics community.
                We wish to foster a culture of diverse and inclusive scientific excellence in which persons of all identities and from all backgrounds feel welcome, supported, and encouraged to engage in free and open exchange of ideas.
                We create our culture, and our culture is inclusive. The code is a guide to ensure we preserve this culture.
            </p>
            <p className="custom-sub-heading">Who is Covered Under the Code</p>
            <p>
                We value the participation of every member of our community at BG24 and BGA24.
                Thus, this Code of Conduct extends to all communications related to the meeting and its attendees, presenters,
                participants, organisers, and exhibitors/sponsors, including livestreamed scientific talks, session Q&A, chats, emails, group discussions, online meetings, and messaging.
            </p>
            <p className="custom-sub-heading">Expectations</p>
            <p>
                All persons can expect to experience a safe, welcoming, and collaborative environment in which they are treated with dignity and respect in a community free of discrimination,
                harassment, bullying, and intimidation based on colour, religion, sex (including pregnancy, childbirth, and related health conditions), gender, sexual orientation, gender identity or expression,
                national origin, ancestry, ethnicity, age, physical or mental disability, citizenship, marital status, genetic information, genetic status or characteristics, military or veteran status,
                or on any basis protected by an applicable law ('protected characteristics').
            </p>
            <p className="custom-sub-heading coc-bullet">1. Be Aware</p>
            <p>
                Certain language and images can offend groups and cultures different from your own.
                If you have any doubt about whether specific language or images in your presentations could be misinterpreted, remove them.
            </p>
            <p className="custom-sub-heading coc-bullet">2. Think Before You Act</p>
            <p>
                We will not tolerate harassment or intimidation of any person, whether verbal, physical, or written (including on our virtual event portal, networking channels, social media, or by email). 
                &nbsp;Any behaviours that do not uphold the highest standards of scientific integrity and professional ethics are not welcome. Offensive and unwelcome behaviours, even if intended as humour, are not permitted.
            </p>
            <p>
                <span style={{ fontWeight: "bold" }}>a. Verbal</span> includes, but is not limited to, epithets, derogatory or demeaning statements, slurs, comments or jokes related to a protected characteristic, 
                unwelcome sexual advances, use of sexual images, excessive disparaging of other’s anyone’s work, or sustained interruption of speakers or those asking questions.
            </p>
            <p>
                <span style={{ fontWeight: "bold" }}>b. Visual</span> includes, but is not limited to: displaying inappropriate images of any sort via any media, sending inappropriate electronic messages, leering,
                or unwelcome sexual gestures or advances, use of sexual images, stalking or requests for sexual favours, or unwanted photography or filming.
            </p>
            <p>
                <span style={{ fontWeight: "bold" }}>c. Intimidation</span> includes, but is not limited to, making threats, bullying, and personal attacks.
            </p>
            <p className="custom-sub-heading coc-bullet">3. Report Concerns</p>
            <p>We encourage all participants to raise concerns as soon as possible for any experienced or witnessed behaviours that do not align with these expectations.
                Please <a href="mailto:biodiversitygenomicsconference-support@sanger.ac.uk">contact us</a> to report Code of Conduct violations or concerns.
            </p>
            <p>
                In response to reports of perceived violations of expectations, the Biodiversity Genomics 2024 team will promptly investigate and take any appropriate corrective action.
                Reports will be kept confidential to the extent possible, however complete confidentiality is not guaranteed.
            </p>
            <p>
                Any form of retaliation for good faith reporting is prohibited and in violation of the Code of Conduct.
            </p>
            <p>
                We expect every member of our community to immediately comply with requests to stop behaviours that are not consistent with these expectations.
                The BGA24 and BG24 teams reserve the right to ask any individual to leave the event if they break the Code of Conduct.
            </p>
            <p>
                This Code of Conduct was initially based on the Code developed by the
                <a href="https://coursesandconferences.wellcomeconnectingscience.org/">
                    &nbsp;Advanced Courses team
                </a> at Wellcome Connecting Science,
                UK in 2020 and has been updated by the
                <a href="https://www.earthbiogenome.org/justice-equity-diversity-inclusion-committee">
                    &nbsp;EBP JEDI committee&nbsp;
                </a>
                in 2021.
            </p>
        </div>
    )

    const components = [
        {
            component: codeOfConduct,
            type: "full"
        }
    ]

    return (
        <Widgets components={components} />
    )
}

export default PracticalInfo;