/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/
import { Widgets } from "@tol/tol-ui";
import { randomGenerator, useStyle, getMidColor } from '../utils/Utils';

function Sponsorships() {
    const { randomImage, startColor, endColor } = randomGenerator();
    const imageSource = require("../assets/" + randomImage);
    const midColor = getMidColor(startColor, endColor);
    useStyle(startColor, endColor, midColor);

    const sponsorships = (
        <div className="information-container">
            <div className="custom-heading" style={{ display: "flex", justifyContent: "center", marginTop: "-15px", marginBottom: "-15px" }}>
                <img src={imageSource} style={{ width: "100%", maxWidth: "300px" }} alt="bga24-banner-logo" />
            </div>
            <p className="custom-heading sponsorship-heading">Sponsorship Opportunities</p>
            <p className="custom-sub-heading" style={{ marginTop: "-20px" }}>
                Sponsors will have the opportunity to showcase their organization, tools,
                services and products to our global network of participants, which in last years edition overcame the 1800 mark.
            </p>
            <p>
                Sponsorship queries can be sent to
                <a href="mailto:biodiversitygenomicsconference-support@sanger.ac.uk"> biodiversitygenomicsconference-support@sanger.ac.uk</a>
            </p>
            <p>Sponsorship packages are detailed below:</p>
            <p className="custom-sub-heading">Level 1</p>
            <ul>
                <li>Acknowledgement of sponsors in welcome session live broadcast.</li>
                <li>Sponsor branding on BG24 website (Live 14 May to 1st November).</li>
                <li>Sponsor branding on conference portal (28th October to 1st November).</li>
                <li>Sponsor branding in holding slides between sessions and during Q+A sessions.</li>
                <li>Company profile on website (50 words).</li>
            </ul>
            <p className="custom-sub-heading">Level 2</p>
            <ul>
                <li><span style={{ fontWeight: "bold" }}>Everything in level 1, plus:</span></li>
                <li>Branding on all social media material and promotion material to be pushed in X and Linkedin by DToL and BG24 channel and our partners.</li>
                <li>Promotional teasers on X and Linkedin to initiate from 14 May.</li>
                <li>Company profile on website (75words).</li>
            </ul>
            <p className="custom-sub-heading">Level 3</p>
            <ul>
                <li><span style={{ fontWeight: "bold" }}>Everything in level 1 and level 2, plus:</span></li>
                <li>
                    20-25 min Talk (pre-recorded and broadcast at conference as part of programme) with live QA 10-15 min to a total 30 minute slot.
                    We will have a limited number of these, max 2 per day during 3 days – broadcast of these sponsor talks will happen day 2 (Europe),
                    day 3 (America) and 4 (APAC) of conference. Talks will be available on youtube for 1 year after conference.
                </li>
                <li>Company profile on website (100 words).</li>
                <li>
                    Sponsorship of 30 minute musical event – event broadcast during day 3 and day 4. Sponsorship include sponsor branding before and after event
                    broadcast and acknowledgement of sponsorship during introduction of event.
                    The musical event will be available on youtube after the conference for 1 year.
                </li>
            </ul>
        </div>
    )

    const components = [
        {
            component: sponsorships,
            type: "full"
        }
    ]

    return (
        <Widgets components={components} />
    )
}

export default Sponsorships;