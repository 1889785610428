/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/
import { Widgets } from "@tol/tol-ui";
import { Tabs } from "rsuite"
import { isMobileOrTablet, randomGenerator, useStyle, getMidColor } from "../utils/Utils"

function Home() {
  const { randomImage, startColor, endColor } = randomGenerator();
  const imageSource = require("../assets/" + randomImage);
  const midColor = getMidColor(startColor, endColor);
  useStyle(startColor, endColor, midColor);

  const home = (
    <div className="information-container">
      <div className="custom-heading" style={{ display: "flex", justifyContent: "center", marginTop: "-15px", marginBottom: "-15px" }}>
        <img src={imageSource} style={{ width: "100%", maxWidth: "400px" }} alt="bga24-banner-logo" />
      </div>
      <div style={{ textAlign: "center" }}>
        <p className="custom-heading landing-heading">BioDiversity Genomics Conference 2024</p>
        <p className="custom-sub-heading" style={{ marginBottom: "3px" }}>28th October - 1st November 2024</p>
        <p className="custom-sub-heading" style={{ paddingBottom: "40px" }}>open | online | global</p>
      </div>
      <p>
        Biodiversity Genomics 2024 (BG24) is back for a fifth year. It will again be open, online and global.
      </p>
      <p>
        BG24 is free to attend, thanks to the generosity of sponsors and core support from the Wellcome Sanger Institute
        Tree of Life Programme. Please engage with us using #BG24 on social media.
      </p>
      <p>
        Join thousands of researchers worldwide to hear all the latest findings and ideas as we strive to complete the goals
        of the <a href="https://www.earthbiogenome.org/" target="_blank" rel="noreferrer">Earth Biogenome Project</a> (EBP): &quot;sequencing life for the future of life.&quot;
      </p>
      <p>
        BG24 will be a mix of sessions with invited talks, including keynote presentations, and open sessions proposed by researchers around the world.
        There will be a focus on regional initiatives and on the global drive to ensure equity and justice in biodiversity sequencing.
      </p>
      <p>
        Join us to catch up on the amazing science showcasing Biodiversity Genomics around the globe.
      </p>
      <p className="custom-sub-heading">Contact</p>
      <p style={{marginTop: "-10px"}}>
        <a href="mailto:biodiversitygenomicsconference-support@sanger.ac.uk">
          biodiversitygenomicsconference-support@sanger.ac.uk
        </a>
      </p>
      <p className="custom-sub-heading">Important Dates</p>
      <table className="home-table wrappable">
        <tr>
          <td style={{ textAlign: "left" }}>Registration Opens:</td>
          <td>15th May 2024</td>
        </tr>
        <tr>
          <td>Registration Deadline:</td>
          <td>28th October 2024</td>
        </tr>
        <tr>
          <td>Abstract Submission Opens:</td>
          <td>22nd May 2024</td>
        </tr>
        <tr>
          <td>Abstract Submission Deadline:</td>
          <td>30th June 2024</td>
        </tr>
      </table>
      <p className="custom-sub-heading" style={{ paddingTop: "20px" }}>Organisers</p>
      <Tabs defaultActiveKey="1" vertical={isMobileOrTablet()}>
        <Tabs.Tab eventKey="1" title="Operational Committee">
          <table className="home-table tab-table wrappable" style={{ paddingLeft: "15px" }}>
            <tr>
              <td>Manuel Batista</td>
              <td>Wellcome Sanger Institute, UK</td>
            </tr>
            <tr>
              <td>Carmen Denman Hume</td>
              <td>Wellcome Sanger Institute, UK</td>
            </tr>
            <tr>
              <td>Rosalind Malik</td>
              <td>Wellcome Sanger Institute, UK</td>
            </tr>
          </table>
        </Tabs.Tab>
        <Tabs.Tab eventKey="2" title="Science Planning Committee">
          <table className="home-table tab-table wrappable" style={{ paddingLeft: "15px" }}>
            <tr>
              <td>Linelle Abueg</td>
              <td>Rockefeller University, USA</td>
            </tr>
            <tr>
              <td>Nicolette Caperello</td>
              <td>Earth BioGenome Project, USA</td>
            </tr>
            <tr>
              <td>Diego de Panis</td>
              <td>Leibniz Institute for Zoo and Wildlife Research, Germany</td>
            </tr>
            <tr>
              <td>Katharina Hoff</td>
              <td>University of Greifswald, Germany</td>
            </tr>
            <tr>
              <td>Peter Holland</td>
              <td>Oxford University, UK</td>
            </tr>
            <tr>
              <td>Kerstin Howe</td>
              <td>Wellcome Sanger Institute, UK</td>
            </tr>
            <tr>
              <td>Josia Kuja</td>
              <td>Washington State University, Global Health Kenya</td>
            </tr>
            <tr>
              <td>Ann McCartney</td>
              <td>University of California, Santa Cruz, USA</td>
            </tr>
            <tr>
              <td>Renato Oliveira</td>
              <td>Vale Institute of Technology, Brasil</td>
            </tr>
            <tr>
              <td>Verena Ras</td>
              <td>University of Cape Town, South Africa</td>
            </tr>
            <tr>
              <td>Xiaofeng Wei</td>
              <td>China National GeneBank, China</td>
            </tr>
          </table>
        </Tabs.Tab>
      </Tabs>
    </div>
  )

  const components = [
    {
      component: home,
      type: "full"
    }
  ]

  return (
    <Widgets components={components} />
  )
};

export default Home;
