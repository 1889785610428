/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/
import { Widgets } from "@tol/tol-ui";
import { randomGenerator, useStyle, getMidColor } from '../utils/Utils';

function PracticalInfo() {
    const { randomImage, startColor, endColor } = randomGenerator();
    const imageSource = require("../assets/" + randomImage);
    const midColor = getMidColor(startColor, endColor);
    useStyle(startColor, endColor, midColor);

    const practicalInfo = (
        <div className="information-container">
            <div className="custom-heading" style={{ display: "flex", justifyContent: "center", marginTop: "-15px", marginBottom: "-15px" }}>
                <img src={imageSource} style={{ width: "100%", maxWidth: "300px" }} alt="bga24-banner-logo" />
            </div>
            <p className="custom-heading">General Information</p>
            <p style={{ fontWeight: "bold" }}>Conference Duration: 28th October 2024 - 1st November 2024.</p>
            <p>
                BG24 will be fully online. For attending the conference sessions, we will provide a link to the conference portal on our Welcome page.
                This will be live shortly before the conference begins.
            </p>
            <p style={{ fontWeight: "bold", paddingTop: "20px", marginBottom: "-1px" }}>Please register for the event
                <a href="https://forms.gle/LLA9XS1sVNrP7CU16" target="_blank" rel="noreferrer"> here</a>.</p>
            <p style={{ fontWeight: "bold", marginBottom: "-1px" }}>Submit your abstract
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSc2U56R9F_AfNjmi6xnMEZGJ88uJcJx5EdpLS1pkCrCgpeisQ/viewform" target="_blank" rel="noreferrer"> here</a>.</p>
            <p style={{ fontWeight: "bold", paddingBottom: "20px" }}>Abstract submission deadline: <span style={{ textDecoration: "underline" }}>7th July 2024</span>.</p>
            <p>
                If your abstract is chosen for a presentation you will be notified in due time before the conference.
                If your presentation is scheduled for a non-Zoom session, you will need to pre-record your talk.
                The recordings will happen during August and you will be able to choose a recording slot that best suits your schedule.
            </p>
        </div>
    )

    const components = [
        {
            component: practicalInfo,
            type: "full"
        }
    ]

    return (
        <Widgets components={components} />
    )
}

export default PracticalInfo;