/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/
import { Widgets } from "@tol/tol-ui";
import { randomGenerator, useStyle, getMidColor } from '../utils/Utils';

function PracticalInfo() {
    const { randomImage, startColor, endColor } = randomGenerator();
    const imageSource = require("../assets/" + randomImage);
    const midColor = getMidColor(startColor, endColor);
    useStyle(startColor, endColor, midColor);

    const codeOfConduct = (
        <div className="information-container">
            <div className="custom-heading" style={{ display: "flex", justifyContent: "center", marginTop: "-15px", marginBottom: "-15px" }}>
                <img src={imageSource} style={{ width: "100%", maxWidth: "300px" }} alt="bga24-banner-logo" />
            </div>
            <p className="custom-heading">Privacy Policy</p>
            <p>
                Thank you for registering with Genome Research Limited (operating as the Wellcome Sanger Institute) for the Biodiversity Genomics Conference 2024 (“we” or “us”). We are committed to protecting and respecting your privacy.
                This Privacy Notice explains how we, as Data Controller, handle the personal data you provide when you register for the Conference.
                It also provides details of the rights that you have under data protection law. Such laws, for the avoidance of doubt, including the UK General Data Protection Regulation (“UK GDPR”),
                the UK Data Protection Act 2018 and the Privacy and Electronic Regulations 2003.
            </p>
            <p className="custom-sub-heading">Our contact details for data protection queries</p>
            <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                <li>Genome Research Limited</li>
                <li>Wellcome Genome Campus, Hinxton, Cambridge, CB10 1SA</li>
                <li>+44 (0)1223 834244</li>
                <li>
                    <a href="mailto:dataprotection@sanger.ac.uk">
                        dataprotection@sanger.ac.uk
                    </a>
                </li>
            </ul>
            <p className="custom-sub-heading">What type of data we collect about data</p>
            <p>
                As part of your registration for the Conference, we collect a variety of data from you and about you,
                including but not limited to the following:
            </p>
            <ul>
                <li>Name</li>
                <li>Contact details (address, email address, social media profile links)</li>
                <li>Country of affiliation</li>
                <li>Job title</li>
                <li>Affiliated institution</li>
                <li>Gender</li>
                <li>Age group</li>
                <li>Area of expertise</li>
                <li>Career level</li>
                <li>Area of interest</li>
                <li>Any other information you provide</li>
            </ul>
            <p>
                This data will be collected directly from you through the Conference registration form. Additional personal data may be collected from you before and during the Conference,
                for example personal data contained within presentations (and their recordings).
            </p>
            <p className="custom-sub-heading">Purposes and legal grounds for processing</p>
            <p>
                The reason we collect this personal data is to register you for the Conference and facilitate your attendance and participation.
            </p>
            <p>
                Our lawful basis for collecting and processing your data for these purposes is legitimate interest. We have carried out a balancing exercise between our
                interests and your rights to ensure your rights are protected when we process your data. In rare circumstances, we may also need to process your data to comply with our legal obligations.
            </p>
            <p className="custom-sub-heading">What we do with the data and who we share it with</p>
            <p>
                We use the data that you have given us for purposes of your registration for, and attendance and participation at, the Conference. Any demographic information you provide will
                be anonymised and analysed by Conference organisers to understand the reach of the Conference and diversity of its attendees.
                We will share your name and affiliation with other Conference participants.
            </p>
            <p>
                If you will be providing any presentations for the Conference, your name and contact details, together with your presentations,
                will be shared with our Audio-Visual supplier, Venue Audio Visual, and your information may be stored securely in their systems.
                Please contact us if you would like more information about this supplier.
            </p>
            <p>
                If you have consented for us to provide your name, affiliation and contact details with our sponsor partners for promotional and marketing activities,
                those partners will provide you information about how they will be processing your data.
            </p>
            <p>
                Other than as noted above, we will not share any of your data with any other third parties, and do not share your data outside the United Kingdom.
            </p>
            <p className="custom-sub-heading">How we store your data and when we delete it</p>
            <p>
                Your personal data is stored on our secure servers with password protection.
                Any physical papers with your information are stored in a secure location in our offices.
            </p>
            <p>
                We will retain your registration data until no later than 31st December 2024 and will then delete your data in compliance with our internal procedures.
                Recorded talks will be made available on the Conference’s streaming channel on YouTube until 2nd November 2025.
            </p>
            <p className="custom-sub-heading">Your data protection rights</p>
            <p>
                You may have the right to request access, rectification or erasure, amongst other rights, in relation to your personal data.
            </p>
            <p>
                Any request to exercise one of these rights will be assessed by us on a case-by-case basis. There may be circumstances in which we are not legally required to comply with a
                request because of relevant exemptions provided for in applicable data protection legislation.
                If we are required to comply, we have one month to respond in most cases.
            </p>
            <p>
                Please contact us at <a href="mailto:dataprotection@sanger.ac.uk">dataprotection@sanger.ac.uk</a> if you wish to make a request.
            </p>
            <p className="custom-sub-heading">How to complain</p>
            <p>
                If you have concerns about how we have handled your personal data, you should get in contact with us at
                <a href="mailto:dataprotection@sanger.ac.uk"> dataprotection@sanger.ac.uk</a> in the first instance.
                If you are dissatisfied with our response, you have the right to complain to the Data Protection Authority.
                In the UK, that is the Information Commissioner’s Office (ICO).
            </p>
            <p>The ICO's contact details:</p>
            <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                <li>Information Commissioner's Office</li>
                <li>Wycliffe House</li>
                <li>Water Lane</li>
                <li>Wilmslow</li>
                <li>Cheshire</li>
                <li>SK9 5AF</li>
                <li>Helpline number: 0303 123 1113</li>
            </ul>
            <p><a href="https://ico.org.uk/make-a-complaint/" target="_blank" rel="noreferrer">https://ico.org.uk/make-a-complaint/</a></p>
            <p>Thank you again for registering for the Biodiversity Genomics Conference 2024.</p>
        </div>
    )

    const components = [
        {
            component: codeOfConduct,
            type: "full"
        }
    ]

    return (
        <Widgets components={components} />
    )
}

export default PracticalInfo;