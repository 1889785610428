/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/
import { useEffect } from "react";

export function isMobileOrTablet() {
    return window.matchMedia('(max-width: 768px)').matches;
}

export function randomGenerator() {
    const red_logo = "BG24_logo_red.png";
    const yellow_logo = "BG24_logo_yellow.png";
    const green_logo = "BG24_logo_green.png";
    const blue_logo = "BG24_logo_blue.png";

    const redStart = "#c51627";
    const redEnd = "#e78658";
    const yellowStart = "#cc9b00";
    const yellowEnd = "#ffd66f";
    const greenStart = "#314b1d";
    const greenEnd = "#36d407";
    const blueStart = "#051758";
    const blueEnd = "#2881b3";

    const imageArray = [red_logo, yellow_logo, green_logo, blue_logo];
    const colorArray = [[redStart, redEnd], [yellowStart, yellowEnd], [greenStart, greenEnd], [blueStart, blueEnd]];
    const randomColorGenerator = Math.floor(Math.random() * colorArray.length);
    const randomImage = imageArray[randomColorGenerator];
    const [startColor, endColor] = colorArray[randomColorGenerator];

    return {
      randomImage,
      startColor,
      endColor
    };
}

export function mixColors(startColor: string, endColor: string, percent: number) {
    startColor = startColor.substring(1);
    endColor = endColor.substring(1);

    const r1 = parseInt(startColor.substring(0, 2), 16);
    const g1 = parseInt(startColor.substring(2, 4), 16);
    const b1 = parseInt(startColor.substring(4, 6), 16);

    const r2 = parseInt(endColor.substring(0, 2), 16);
    const g2 = parseInt(endColor.substring(2, 4), 16);
    const b2 = parseInt(endColor.substring(4, 6), 16);

    const r = Math.round(r1 * (1 - percent) + r2 * percent);
    const g = Math.round(g1 * (1 - percent) + g2 * percent);
    const b = Math.round(b1 * (1 - percent) + b2 * percent);

    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export function getMidColor(startColor: string, endColor: string) {

    const mixedColor = mixColors(startColor, endColor, 0.6);

    return mixedColor;
}

export function useStyle(startColor: string, endColor: string, midColor: string) {
    useEffect(() => {
        document.documentElement.style.setProperty('--gradient-start-color', startColor);
        document.documentElement.style.setProperty('--gradient-end-color', endColor);
        document.documentElement.style.setProperty('--gradient-mid-color', midColor);
    }, [startColor, endColor, midColor]);
}