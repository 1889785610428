/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/
import { Widgets } from "@tol/tol-ui";
import { randomGenerator, useStyle, getMidColor } from '../utils/Utils';

function BGA24() {
    const { startColor, endColor } = randomGenerator();
    const imageSource = require("../assets/bga24.png");
    const midColor = getMidColor(startColor, endColor);
    useStyle(startColor, endColor, midColor);

    const bga24Info = (
        <div className="information-container">
            <div className="custom-heading" style={{ display: "flex", justifyContent: "center", marginTop: "-15px", marginBottom: "-15px" }}>
                <img src={imageSource} style={{ width: "100%", maxWidth: "300px" }} alt="bga24-banner-logo" />
            </div>
            <p className="custom-heading">BGA24</p>
            <p style={{ marginBottom: "20px" }}>
                BG24 runs hand-in-hand with the BioDiversity Genomics Academy 2024 (BGA24).
                BGA24 is a series of free, open-to-all, online-only short, interactive sessions on how to use the bioinformatics tools and approaches that
                underpin the <a href="https://www.earthbiogenome.org/" target="_blank" rel="noreferrer">Earth Biogenome Project</a> (EBP).
            </p>
            <p style={{ marginBottom: "20px" }}>
                BGA24 attendees will be able to discuss the installation and operation of bioinformatics toolkits in real time with developers,
                take part in introductory exercises and masterclasses, and meet the other scientists who are also using the tools, building a
                global community of practice in biodiversity genomics. This year we hope to extend the workshops beyond bioinformaticians and also
                feature wet lab, taxonomy and other sessions related to EBP research.
            </p>
            <p>
                The workshops are scheduled for 1st - 25th October 2024, more information can be found at <a href="http://thebgacademy.org" target="_blank" rel="noreferrer">thebgacademy.org</a>.
            </p>
        </div>
    )

    const components = [
        {
            component: bga24Info,
            type: "full"
        }
    ]

    return (
        <Widgets components={components} />
    )
};

export default BGA24;
