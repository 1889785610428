/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/
import { Widgets } from "@tol/tol-ui";
import { Tabs } from 'rsuite';
import { isMobileOrTablet, randomGenerator, useStyle, getMidColor } from "../utils/Utils";

function RegistrationAndAbstracts() {
    const { randomImage, startColor, endColor } = randomGenerator();
    const imageSource = require("../assets/" + randomImage);
    const midColor = getMidColor(startColor, endColor);
    useStyle(startColor, endColor, midColor);

    const registrationAndAbstracts = (
        <div className="information-container">
            <div className="custom-heading" style={{ display: "flex", justifyContent: "center", marginTop: "-15px", marginBottom: "-15px" }}>
                <img src={imageSource} style={{ width: "100%", maxWidth: "300px" }} alt="bga24-banner-logo" />
            </div>
            <p className="custom-heading">Registration and Abstract Submission</p>
            <p className="custom-sub-heading">Registration</p>
            <p>
                This is a fully accessible conference. Registration is free. Please register and attend, and invite your colleagues and collaborators to register as well.
                We look forward to welcoming you all to this fantastic conference!
            </p>
            <p>Registration opens: 15th May 2024</p>
            <p style={{ fontWeight: "bold" }}>Registration deadline: 28th October 2024</p>
            <p className="call-to-action">
                Please register for the event
                <a href="https://forms.gle/LLA9XS1sVNrP7CU16" target="_blank" rel="noreferrer"> here</a>.
            </p>
            <p className="custom-sub-heading" style={{ marginTop: "30px" }}>Abstract Submission</p>
            <p>We invite you to submit an abstract for a poster or a talk in one of the many sessions.</p>
            <p>Abstract submissions open: 22nd May 2024</p>
            <p style={{ fontWeight: "bold" }}>Abstract deadline: <span style={{ textDecoration: "underline" }}>7th July 2024</span></p>
            <p className="call-to-action" style={{ marginBottom: "30px" }}>
                Abstract submission is now closed.
            </p>
            <div>
                <p className="custom-sub-heading">Abstract Rules</p>
                <ul>
                    <li><span style={{ fontWeight: "bold" }}>Title:</span> The abstract title is limited to 50 characters.</li>
                    <li>
                        <span style={{ fontWeight: "bold" }}>Authors and affiliations:</span> Please enter authors' name and affiliations in the dedicated fields.
                        Do not use capital letters unless using abbreviations or similar.
                    </li>
                    <li><span style={{ fontWeight: "bold" }}>Text:</span> Word count is limited to 250 words. The title, authors' name and affiliations are not included in this number.</li>
                    <li><span style={{ fontWeight: "bold" }}>Image:</span> The abstract allows for no images.</li>
                    <li><span style={{ fontWeight: "bold" }}>Presentation type:</span> Please indicate the preferred presentation type.</li>
                    <li><span style={{ fontWeight: "bold" }}>Categories:</span> You can choose up to two categories for your abstract.</li>
                </ul>
            </div>
            <div>
                <p className="custom-sub-heading" style={{ paddingBottom: "20px" }}>Categories</p>
                <Tabs defaultActiveKey="1" vertical={isMobileOrTablet()}>
                    <Tabs.Tab eventKey="1" title="BioDiversity Genomics Regional Sessions">
                        <table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Session Title</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/1V-15Y0s2fsMXIFsJ5sTcmBVkjyUcqwKWq3BE8VyHaiM/edit">
                                            BioDiversity Genomics in the Americas
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/1V-15Y0s2fsMXIFsJ5sTcmBVkjyUcqwKWq3BE8VyHaiM/edit">
                                            BioDiversity Genomics in Europe
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/1V-15Y0s2fsMXIFsJ5sTcmBVkjyUcqwKWq3BE8VyHaiM/edit">
                                            BioDiversity Genomics in Africa
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/1V-15Y0s2fsMXIFsJ5sTcmBVkjyUcqwKWq3BE8VyHaiM/edit">
                                            BioDiversity Genomics in Australasia
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Tabs.Tab>
                    <Tabs.Tab eventKey="2" title="Topical Sessions">
                        <table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Session Title</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>5.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/1nCZ9Mcktnw_cXZFmeGgLWcbM8WmvrFjuw2gDFeq4Uf8/edit">
                                            Genomics Technologies
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>6.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/1bnT8VnOynNdShmki-wB_yHhfT40q6BqN0JsV1pRjsvE/edit" target="_blank" rel="noreferrer">
                                            Beyond the reference: genomics for amphibian research and conservation
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>7.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/1VA-sOiYXHH8gYym12En9AgWuPBwGfjiFCtSQyF6Csdg/edit" target="_blank" rel="noreferrer">
                                            Genome After-Party: a repository of ready-made genome analysis
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>8.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/15hFliq7I7RZeCplNJEjJ__uhEiQ44nEtjSFwAHOqXy4/edit">
                                            Genomics in the Neotropics
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>9.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/1YOimYE3wdMX8o_BCv-fI9v36dobdm4EOwdqnDN4RE3M/edit?usp=sharing" target="_blank" rel="noreferrer">
                                            Indigenous Data Sovereignty in Biodiversity Genomics
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>10.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/1kIGXaZip9EduLPRhSIQ3AV17v52OMiEq81wEvcVnl-8/edit" target="_blank" rel="noreferrer">
                                            Marine vertebrate genomics
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>11.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/1z4frs1_vubfWpHx8Jzc4j6V5ZrBxj-3xpogC3YPYSGk/edit" target="_blank" rel="noreferrer">
                                            Protists reference genomes: gaps, challenges and opportunities
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>12.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/1VA2gm_sWjknaEJBTeaQi_hHUWGZ_vqicQnnHKb94nl8/edit" target="_blank" rel="noreferrer">
                                            Research of ECRs who work in biodiversity genomics
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>13.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/1caRxup-n5InEj9elk5avz-rR-2cALDgrx2qlEO8so_U/edit" target="_blank" rel="noreferrer">
                                            Tools for eDNA and metagenomics
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>14.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/1cbg1RLc80jE2fb8jEIoPH56kIZ2znsbYWmbKRsZGk3o/edit" target="_blank" rel="noreferrer">
                                            Transposon evolution in the era of Biodiversity Genomics
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>15.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/1RML_iol1wv1xSaaThNLK7uYXkKRhMxFQPzvyaJ2F3gs/edit?usp=sharing" target="_blank" rel="noreferrer">
                                            Tree of Sex
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Tabs.Tab>
                    <Tabs.Tab eventKey="3" title="EBP Project Meetings (Zoom)">
                        <table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Session Title</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>16.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/1j5H78Qmj3-Yfsqx4mmLAIKUv3Qfg3lyJIfleHhJDSiM/edit" target="_blank" rel="noreferrer">
                                            Aquatic Symbiosis Genomics
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>17.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/10vOtD0bhrdeCdEcaTLU321t_d2335kAZC06D25S9V80/edit" target="_blank" rel="noreferrer">
                                            BGE: The European Reference Genome Atlas Community
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>18.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/10vOtD0bhrdeCdEcaTLU321t_d2335kAZC06D25S9V80/edit" target="_blank" rel="noreferrer">
                                            BGE: iBOL Europe: A Regional Nexus for the DNA barcoding community
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>19.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/10vOtD0bhrdeCdEcaTLU321t_d2335kAZC06D25S9V80/edit" target="_blank" rel="noreferrer">
                                            BGE: Biodiversity Genomics Europe: Scaling up capacity, production, and application
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>20.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/1unXCI3oFhbtKcBtCF-14gmraIWCdgnbpoTB4CTNwn8c/edit" target="_blank" rel="noreferrer">
                                            California Conservation Genomics Project
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>21.</td>
                                    <td>
                                        <a href="https://www.earthbiogenome.org/subcommittee-ethical-legal-social" target="_blank" rel="noreferrer">
                                            EBP Ethics Committee
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>22.</td>
                                    <td>
                                        <a href="https://www.earthbiogenome.org/justice-equity-diversity-inclusion-committee" target="_blank" rel="noreferrer">
                                            EBP JEDI Committee
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>23.</td>
                                    <td>
                                        <a href="https://i5k.github.io/" target="_blank" rel="noreferrer">
                                            i5K
                                        </a>
                                        ;&nbsp;
                                        <a href="https://docs.google.com/document/d/1M_0QSL07ip6vVP6jVrlwcaIlpUa8wbydp-A4dZNs__o/edit" target="_blank" rel="noreferrer">
                                            Exploring the evolution and genomic basis of beetle (Coleoptera) diversity
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>24.</td>
                                    <td>
                                        <a href="https://i5k.github.io/" target="_blank" rel="noreferrer">
                                            i5K
                                        </a>
                                        ;&nbsp;
                                        <a href="https://docs.google.com/document/d/17dosvRZT0KnyCh-u3zhxY_YPqLETuQZ7FdX0f2nUpA8/edit" target="_blank" rel="noreferrer">
                                            i5K Community Meeting
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>25.</td>
                                    <td>
                                        <a href="https://i5k.github.io/" target="_blank" rel="noreferrer">
                                            i5K
                                        </a>
                                        ;&nbsp;
                                        <a href="https://docs.google.com/document/d/1Fjf6gPoVogm7ZISvfGhxzlvryi0DyKnBCe_Mi6UDaxo/edit" target="_blank" rel="noreferrer">
                                            Beenomes and Badnomes: Advances in applied insect Genomics
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>26.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/1j81cjDE4pra2mM0TF4ykJT_E-mWIK7ebxos8IUjhTY4/edit?usp=sharing" target="_blank" rel="noreferrer">
                                            Ruminant T2T consortium
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>27.</td>
                                    <td>
                                        <a href="https://docs.google.com/document/d/195mxvg8vt0sIUtC6B8WkxHIF9KaD9XNR2eV-izUcK5M/edit" target="_blank" rel="noreferrer">
                                            Vertebrate Genomes Project
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Tabs.Tab>
                </Tabs>
            </div>
        </div>
    )

    const components = [
        {
            component: registrationAndAbstracts,
            type: "full"
        }
    ]

    return (
        <Widgets components={components} />
    )
}

export default RegistrationAndAbstracts;