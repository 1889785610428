/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/
import { useEffect, useState, useMemo } from "react";
import { Widgets, Col, Row } from "@tol/tol-ui";
import { Sidenav, Nav } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faMagnifyingGlass,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  randomGenerator,
  useStyle,
  getMidColor
} from "../utils/Utils";

function Abstracts() {
  const randomStyle = useMemo(() => {
    const { randomImage, startColor, endColor } = randomGenerator();
    const midColor = getMidColor(startColor, endColor);
    return {
      imageSource: require("../assets/" + randomImage),
      startColor,
      endColor,
      midColor,
    };
  }, []);

  const imageSource = randomStyle.imageSource;
  useStyle(randomStyle.startColor, randomStyle.endColor, randomStyle.midColor);

  const [abstractAuthor, setAbstractAuthor] = useState<string[]>([]);
  const [authorAffiliation, setAuthorAffiliation] = useState<string[]>([]);
  const [abstractTitle, setAbstractTitle] = useState<string[]>([]);
  const [abstractContent, setAbstractContent] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showScrollButton, setShowScrollButton] = useState<boolean>(false);
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);

  useEffect(() => {
    const data = require("../assets/abstract_data.json");
    const author = Object.keys(data);

    const extractedAffiliations = author.map((a) => data[a].organisation);
    const extractedTitles = author.map((a) => data[a].abstract_title);
    const extractedContents = author.map((a) => data[a].abstract_content);

    setAbstractAuthor(author);
    setAuthorAffiliation(extractedAffiliations);
    setAbstractTitle(extractedTitles);
    setAbstractContent(extractedContents);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      window.history.replaceState({}, "", "#abstracts");
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollButton(window.scrollY > 600);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleSearch = () => setShowSearchBar(!showSearchBar);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollButton = (
    <button
      onClick={scrollToTop}
      className="scroll-to-top-button button-container"
      style={{
        display: showScrollButton ? "block" : "none",
      }}
    >
      <FontAwesomeIcon icon={faArrowUp} />
    </button>
  );

  const filteredAuthors = useMemo(() => {
    return abstractAuthor.filter((author, index) => {
      const searchString = searchTerm.toLowerCase();
      return (
        author.toLowerCase().includes(searchString) ||
        abstractTitle[index].toLowerCase().includes(searchString) ||
        abstractContent[index].toLowerCase().includes(searchString) ||
        authorAffiliation[index].toLowerCase().includes(searchString)
      );
    });
  }, [
    abstractAuthor,
    abstractTitle,
    abstractContent,
    authorAffiliation,
    searchTerm,
  ]);

  const searchBar = (
    <div id="abstract-search-bar">
      <input
        type="text"
        placeholder="Search abstracts/authors..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{
          width: "80%",
          fontSize: "12px",
          padding: "8px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          display: "block",
          margin: "14px auto",
        }}
      />
    </div>
  );

  const sideNavbar = (
    <div className="sidenav-container">
      <Sidenav>
        <Sidenav.Body>
          <Nav activeKey="1">
            <Nav.Menu
              eventKey="1"
              title="Abstract Search"
            >
              {searchBar}
              {[...filteredAuthors].sort().map((creator, index) => (
                <Nav.Item
                  key={index}
                  eventKey={String(index + 1)}
                  href={`#${encodeURIComponent(creator)}`}
                >
                  {creator}
                </Nav.Item>
              ))}
            </Nav.Menu>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </div>
  );

  const abstracts = (
    <div className="information-container">
      <div
        className="custom-heading"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "-15px",
          marginBottom: "-15px",
        }}
      >
        <img
          src={imageSource}
          style={{ width: "100%", maxWidth: "300px" }}
          alt="bga24-banner-logo"
        />
      </div>
      <Row style={{ marginTop: "30px" }}>
        <Col
          lg={3}
          md={12}
          sm={12}
        >
          <div className="desktop-search-bar">{sideNavbar}</div>
        </Col>
        <Col
          lg={9}
          md={12}
          sm={12}
        >
          <div className="mobile-title-search-button" style={{ marginBottom: !showSearchBar ? "30px" : "10px" }}>
            <p className="custom-heading">Abstracts</p>
            <button
              className="button-container show-mobile-search-button hide-mobile-search-button mobile-search-button"
              onClick={toggleSearch}
            >
              <FontAwesomeIcon
                icon={!showSearchBar ? faMagnifyingGlass : faXmark}
                className={`icon-transition ${
                  showSearchBar ? "icon-rotate" : ""
                }`}
              />
            </button>
          </div>
          <div
            className={`mobile-search-bar ${!showSearchBar ? "hidden" : ""}`}
          >
            {sideNavbar}
          </div>
          {filteredAuthors.map((author) => {
            const originalIndex = abstractAuthor.indexOf(author);
            return (
              <div
                key={author}
                id={author}
                style={{ scrollMarginTop: "80px", marginTop: showSearchBar ? "10px" : "0px" }}
              >
                <h2 className="custom-heading abstract-author">{author}</h2>
                <p className="author-affiliation">
                  {authorAffiliation[originalIndex]}
                </p>
                <p className="abstract-title">{abstractTitle[originalIndex]}</p>
                <p className="abstract-content">
                  {abstractContent[originalIndex]}
                </p>
                <span className="abstract-seperator"></span>
              </div>
            );
          })}
        </Col>
      </Row>
    </div>
  );

  const components = [
    {
      component: abstracts,
      type: "full",
    },
  ];

  return (
    <div>
      <Widgets components={components} />
      {scrollButton}
    </div>
  );
}

export default Abstracts;