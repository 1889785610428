/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/
import { Widgets } from "@tol/tol-ui";
import { randomGenerator, useStyle, getMidColor } from '../utils/Utils';

function PracticalInfo() {
    const { randomImage, startColor, endColor } = randomGenerator();
    const imageSource = require("../assets/" + randomImage);
    const midColor = getMidColor(startColor, endColor);
    useStyle(startColor, endColor, midColor);

    const codeOfConduct = (
        <div className="information-container">
            <div className="custom-heading" style={{ display: "flex", justifyContent: "center", marginTop: "-15px", marginBottom: "-15px" }}>
                <img src={imageSource} style={{ width: "100%", maxWidth: "300px" }} alt="bga24-banner-logo" />
            </div>
            <p className="custom-heading">Terms and Conditions (T&Cs)</p>
            <p>
                Due to the online nature of BG24,
                the organisers will need to obtain and store certain information from the attendees and all attendees are required to agree to the following conditions.
            </p>
            <ol>
                <li className="tandc-heading">Presentations
                    <ol type="a">
                        <li>
                            All  presentations  will be pre recorded by our AV supplier (Venue Audio Visual),
                            and attendees selected for oral presentation (Presenters) agree to pre-record their presentation
                        </li>
                        <li>
                            Presenters agree to attend an online recording slot as booked by Presenter on AV supplier’s website.
                            Failure to attend the recording slot might result in the Presenters' invitation to be forfeit
                        </li>
                        <li>
                            Recordings will be edited for removal of errors, etc.
                        </li>
                        <li>
                            Pre-recordings will be saved in a secure drive by the AV supplier and will be streamed on the conference portal between 28 October 2024 and 1st November 2024
                        </li>
                        <li>
                            Recorded talks will be made available on the conference's streaming channel on YouTube until 2nd November 2025
                        </li>
                        <li>
                            In the event that a presentation is agreed to occur live (such as the community session on 28th October and 1st November), the Presenter accepts the risks associated with live streaming.
                            The live streaming will be recorded and handled subsequently as the pre-recordings mentioned in points above.
                        </li>
                    </ol>
                </li>
                <li className="tandc-heading">Poster presentations
                    <ol type="a">
                        <li>
                            Selected poster presentations will need to be submitted by the Presenters to our AV supplier
                            (Venue Audio Visual) and/or the organisers as advised in the acceptance communication
                        </li>
                        <li>
                            Posters will need to be submitted in a PDF format in a timeline manner as requested by the AV supplier and/or the organisers
                        </li>
                        <li>
                            Failure to submit posters in a timely manner might result with the selected poster being removed from the session
                        </li>
                        <li>
                            Poster files will be saved in a secure drive by the AV supplier and will be available on the conference portal between 28 October 2024 and 1st November 2024
                        </li>
                        <li>
                            Posters will be made available on the conference's web repository up to 2nd November 2025
                        </li>
                    </ol>
                </li>
                <li className="tandc-heading">Abstract submission
                    <ol type="a">
                        <li>
                            Data obtained through abstract submission will be collected by organisers and maintained in a secure location up to 31st December 2025
                        </li>
                        <li>
                            Abstract submissions data will be deleted no later than 31st December 2025
                        </li>
                        <li>
                            Abstracts (including Title, Authorship and Affiliation)
                            will be shared with the review panel for the purpose of selection for oral or poster presentation.
                        </li>
                        <li>
                            Selected abstracts (including Title, Authorship and Affiliation) will be shared in the BG24 website throughout it’s lifespan.
                        </li>
                        <li>
                            Selected abstracts (including Title, Authorship and Affiliation) will be shared in BG24 conference portal during the conference.
                        </li>
                        <li>
                            Abstracts will only be accepted from attendees registered to the BG24 conference up 30TH of June
                        </li>
                        <li>
                            Abstracts submitted after 30 June 2024 will not be accepted
                        </li>
                        <li>
                            Abstracts that exceed the character/word count indicated (title max 50 characters and abstract max 250 words) will not be accepted
                        </li>
                        <li>
                            Abstracts that are duplicated, contain material deemed offensive or inappropriate content will not be accepted.
                        </li>
                    </ol>
                </li>
                <li className="tandc-heading">Registration data
                    <ol type="a">
                        <li>
                        Data obtained through registration will be collected by organisers and maintained in a secure location up to 31st December 2024
                        </li>
                        <li>
                        Registration data will be deleted no later than 31st December 2024
                        </li>
                        <li>
                        The data obtained from attendees will be used to contact attendees regarding matters of importance to the BG24 conference, 
                        such as communication of selection to an oral presentation or a poster presentation and arranging of a presentation recording
                        </li>
                        <li>
                        Demographic data will be anonymised and analysed as a whole to assess the reach of the conference and the diversity of attendees
                        </li>
                        <li>
                        If attendees opt in to receive marketing and promotional materials from conference sponsors, only name, 
                        email address and affiliation of attendees will be shared with these sponsors
                        </li>
                        <li>
                        Please see our conference 
                        <a href="https://docs.google.com/document/d/1zOzr8ad8rrHI7nHTNWSupdgeQQTeT2Ux/edit"> Privacy Notice </a> 
                        for details about what data will be collected from attendees and how it will be processed.
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
    )

    const components = [
        {
            component: codeOfConduct,
            type: "full"
        }
    ]

    return (
        <Widgets components={components} />
    )
}

export default PracticalInfo;